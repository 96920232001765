import BaseCom from "./BaseCom";
import Icon from "./Icon";

class ComPreview extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            com: props.com
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.com !== prevProps.com) {
            var com = { ...this.props.com };
            if (com.element === 'li' || com.element === 'img')
                com.element = 'div';
            this.setState({ com: com });
        }
    }

    name = (v) => {
        if(!v) return '';
        if(v.startsWith('Heading '))
            v = v.replace('Heading ', 'H');
        return v;
    }

    render() {
        return <div className="text-center">
            {this.props.com.previewMode === 'image' ?
            <div className="border rounded bg-light text-center d-flex flex-column align-items-center justify-content-center" style={{ height: '100px' }}>
                <div className="p-2">
                    <img src={this.props.com.imageSrc} alt={this.props.com.name} style={{width: '35px' }} />
                </div>
                <p style={{fontSize: '0.8rem'}}>{this.props.com.name}</p>
            </div>
            : this.props.com.editor === 'menu' ? <>
                <nav className="navbar navbar-light bg-light">
                    <div class="container-fluid">
                        <a className="navbar-brand">Navbar</a>
                    </div>
                </nav>
            </> :
                <this.state.com.element className={this.props.com.className}>
                    {this.props.com.innerElement === 'img' ? <div className="fs-1"><Icon icon="faImage" /></div> : ''}
                    {this.name(this.props.com.name)}
                </this.state.com.element>}
        </div>;
    }
}

export default ComPreview;