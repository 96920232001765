import { Tooltip } from "react-tooltip";
import BaseCom from "./BaseCom";
import L from "./Lang";
import './Tutorial.css';
import React from "react";
import { qreq } from "../shared/qrequest";

class Bubble extends BaseCom {
    render() {
        return (
            <Tooltip isOpen anchorSelect={this.props.anchorSelect} place={this.props.place} style={{ zIndex: 1999, pointerEvents: 'all', borderRadius: '25px', boxShadow: '0px 0px 10px #fff', padding: '15px' }}>
                <div className="fs-5">
                    {this.props.children}
                </div>
                <div className="d-flex gap-1 justify-content-end mt-1">
                    <button type="button" onClick={this.props.onEnd} className="btn text-warning">Skip Tutorial</button>
                    {this.props.currentStep > 0 && <button type="button" onClick={this.props.onPrev} className="btn btn-secondary">Prev</button>}
                    <button type="button" onClick={this.props.onNext} className="btn btn-primary">{this.props.last ? <L>Finish Tutorial</L> : <L>Next</L>}</button>
                </div>
            </Tooltip>
        );
    }
}

export default class Tutorial extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            steps: [
                {
                    anchorSelect: '.tt-com',
                    place: 'right',
                    content: <L>Use the "Tools" menu to add new components to the website.</L>
                },
                {
                    anchorSelect: '.tt-pages',
                    place: 'right',
                    content: <L>Use the "Pages" menu to add new pages to the website.</L>
                },
                {
                    anchorSelect: '.tt-offcanvas-h5',
                    place: 'left',
                    content: <L>Whenever you click an element, this menu will pop up, you can change the styles here.</L>,
                    onCurrent: () => {
                        this.props.setToggle('design', true);
                    },
                    delay: 500
                },
                {
                    anchorSelect: '.tt-save',
                    place: 'bottom',
                    content: <L>Click here to save your changes. Do this before you preview.</L>,
                    onCurrent: () => {
                        this.props.setToggle(null, false);
                    }
                },
                {
                    anchorSelect: '.tt-preview',
                    place: 'bottom',
                    content: <L>Click here to preview your website. Your website will not be live until published.</L>
                },
                {
                    anchorSelect: '.tt-purchase',
                    place: 'bottom',
                    content: <L>Once you are ready, click here to purchase the website. Your website will be live and visible to everyone once purchased.</L>
                }
            ],
            anchorSelect: '.tt-com',
            currentStep: 0
        };
        this.bgTop = React.createRef();
        this.bgLeft = React.createRef();
        this.bgRight = React.createRef();
        this.bgBottom = React.createRef();
    }

    async componentDidMount() {
        this.highlight();

        try
        {
            let j = await qreq.getAsync('/api/tutorial/status');
            if (j.errorCode === 0 && !j.item)
                this.setState({ show: true });
        }
        catch
        {}
    }

    getStep = () => {
        return this.state.steps[this.state.currentStep];
    }

    highlight = () => {
        setTimeout(() => {
            if (!this.getStep()) return;

            let current = this.getStep();
            if (current.onCurrent)
                current.onCurrent();

            setTimeout(() => {
                let el = document.querySelector(this.getStep().anchorSelect);
                if (el) {
                    let rect = el.getBoundingClientRect();
                    if (!this.bgTop.current || !this.bgLeft.current || !this.bgRight.current || !this.bgBottom.current)
                        return;
                    this.bgTop.current.style.height = rect.top + 'px';
                    this.bgTop.current.style.width = '100%';
                    this.bgTop.current.style.left = '0';
                    this.bgTop.current.style.top = '0';

                    this.bgLeft.current.style.height = (rect.bottom - rect.top) + 'px';
                    this.bgLeft.current.style.width = rect.left + 'px';
                    this.bgLeft.current.style.left = '0';
                    this.bgLeft.current.style.top = rect.top + 'px';

                    this.bgRight.current.style.height = (rect.bottom - rect.top) + 'px';
                    this.bgRight.current.style.width = window.innerWidth - rect.right + 'px';
                    this.bgRight.current.style.left = rect.right + 'px';
                    this.bgRight.current.style.top = rect.top + 'px';

                    this.bgBottom.current.style.height = window.innerHeight - rect.bottom + 'px';
                    this.bgBottom.current.style.width = '100%';
                    this.bgBottom.current.style.left = '0';
                    this.bgBottom.current.style.top = rect.bottom + 'px';
                }
                this.setState({ anchorSelect: this.getStep().anchorSelect });
            }, this.getStep().delay || 0);


        }, 100);
    }

    next = () => {
        if (this.state.currentStep >= this.state.steps.length - 1) {
            this.end();
            return;
        }
        this.setState({ currentStep: this.state.currentStep + 1 });
        this.highlight();
    }

    prev = () => {
        if (this.state.currentStep === 0) return;
        this.setState({ currentStep: this.state.currentStep - 1 });
        this.highlight();
    }

    end = () => {
        this.setState({ show: false });
        qreq.post('/api/tutorial/done', {}, j => {});
    }

    render() {
        if (!this.state.show) return null;
        return (<>
            <div ref={this.bgTop} className="tutorial-bg"></div>
            <div ref={this.bgLeft} className="tutorial-bg"></div>
            <div ref={this.bgRight} className="tutorial-bg"></div>
            <div ref={this.bgBottom} className="tutorial-bg"></div>
            <Bubble currentStep={this.state.currentStep} last={this.state.currentStep === this.state.steps.length - 1} anchorSelect={this.getStep()?.anchorSelect} place={this.getStep()?.place} onNext={this.next} onPrev={this.prev} onEnd={this.end}>
                {this.getStep()?.content}
            </Bubble>
        </>
        );
    }
}