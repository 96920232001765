import React from "react";
import { qreq } from "../shared/qrequest";
import AdminLayout from "./AdminLayout";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import { Pop } from "./PopModal";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import Globals from "../shared/Globals";
import L from "./Lang";

class AdminTemplatesEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {}
        };
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this);
        this.uploadRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.params.id) {
            qreq.get('/api/template/get/' + this.props.params.id, j => {
                if (j.item)
                    this.setState({ info: j.item });
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
        }
    }

    delete() {
        Pop.showConfirm('Are you sure you want to delete this template?', 'Delete', () => {
            qreq.post('/api/template/admin/delete', {...this.state.info}, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success('Template deleted!');
                    this.props.navigate('/admin/templates');
                }
            }, this.unkownErrorCallback);
        });
    }

    submit(e) {
        e?.preventDefault();
        this.setState({ submitting: true });
        if(this.state.info.previewBase64Image) {
            Pop.show(<L>Please wait...</L>, <L>Uploading</L>, true);
        }
        qreq.post('/api/template/admin/save', { ...this.state.info }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Template properties saved!');
                this.props.navigate('/admin/templates');
            }
            this.setState({ submitting: false });
            Pop.hide();
        }, () => {
            this.setState({ submitting: false });
            this.unkownErrorCallback();
            Pop.hide();
        });
    }

    upload = (e) => {
        var file = e.target.files[0];
        if (!file)
            return;

        var reader = new FileReader();
        reader.onload = () => {
            let base64 = reader.result.split(',')[1];
            this.setState({ info: { ...this.state.info, previewBase64Image: base64 }, uploadThumbnail: reader.result });
        };
        reader.readAsDataURL(file);
    }

    render() {
        return <AdminLayout>
            <div className="container">
                <form onSubmit={this.submit}>
                    <Card title="Template" headerButtons={<button type="button" className="btn btn-danger" onClick={this.delete}><Icon icon="faTrash" /></button>}>
                        <FormInput model={this.state.info} name="templateName" label="Template Name" type="text" />
                        <input type="file" className="d-none" ref={this.uploadRef} onChange={this.upload} />
                        <div className="text-center">
                            <img src={this.state.uploadThumbnail ? this.state.uploadThumbnail : Globals.imageUrl('/api/media/site/preview/' + this.state.info.masterID)} alt="" className="d-block img-fluid mx-auto my-1" />
                            <button type="button" className="btn btn-primary" onClick={() => this.uploadRef.current.click()}><Icon icon="faUpload" /> Upload New Thumbnail</button>
                        </div>
                    </Card>

                    <div className="app-form-buttons">
                        <button onClick={this.goBack} type="button" className="btn btn-secondary" disabled={this.state.submitting}><Icon icon="faCancel" /> Cancel</button>
                        <button type="submit" className="btn btn-success" disabled={this.state.submitting}><Icon icon="faSave" /> Save</button>
                    </div>
                </form>
            </div>
        </AdminLayout>
    }
}

export default withParamsAndNavigate(AdminTemplatesEdit);