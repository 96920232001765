/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { EditorContainer } from './EditorContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropertiesEditor from './PropertiesEditor';
import LinkEditor from './LinkEditor';
import EditorColToolbar from './EditorColToolbar';
import BaseCom from './BaseCom';
import { qreq } from '../shared/qrequest';
import { EditorGlobal } from './Editor';
import { NavEditorGlobal } from './NavEditorModal';
import L from './Lang';


export class EditorColNav extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.ver !== NavEditorGlobal.ver) {
            this.load();
            this.setState({ ver: NavEditorGlobal.ver });
        }
    }

    load() {
        if(EditorGlobal.siteDomainID) {
            qreq.get('/api/nav/get?siteDomainID=' + EditorGlobal.siteDomainID, j => {
                if(j.item)
                    this.setState({ list: j.item.items });
            });
        }
    }

    render() {
        return this.state.list.map((item, i) => <li className="nav-item" key={i} style={this.props.style}>
            <a href="#" onClick={e => e.preventDefault()} className="nav-link" style={this.props.linkStyle}>{item.name}</a>
        </li>);
    }
}
 
export class EditorCol extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            container: props.container,
            col: props.col,
            showEditor: props.col.showEditor ?? false,
            colSz: props.col.sz ?? '-md',
            tag: props.col.com.element ?? 'div',
            idDiv: 'wbp-container-' + props.container.id + '-col-' + props.col.id
        };
        this.state.col.parentId = props.parentId;
        this.subContainer = props.subContainer;
        this.focusMove = props.focusMove;
        this.state.idDiv = 'wbp-container-' + props.container.id + '-col-' + props.col.id;
        this.isFocused = false;
        this.setSelectedColumn = props.setSelectedColumn;
        this.setMainToolbarStatus = props.setMainToolbarStatus;
        this.getClass = this.getClass.bind(this);
        this.getComClass = this.getComClass.bind(this);
        this.showToolbar = this.showToolbar.bind(this);
        this.hideToolbar = this.hideToolbar.bind(this);
        this.hovered = this.hovered.bind(this);
        this.blurred = this.blurred.bind(this);
        this.htmlToBody = this.htmlToBody.bind(this);        
        this.hideToolbarTimer = null;
        this.createCol = props.createCol;
        this.removeCol = props.removeCol;
        this.removeColByID = props.removeColByID;
        this.onInput = this.onInput.bind(this);
        this.showPropsModal = this.showPropsModal.bind(this);
        this.showComProps = props.showComProps;
        this.onBodyChange = this.onBodyChange.bind(this);
        this.detectPosition = this.detectPosition.bind(this);
        this.resize = this.resize.bind(this);
        this.mouseDown = this.mouseDown.bind(this);
        this.mouseUp = this.mouseUp.bind(this);
        this.calcColSize = this.calcColSize.bind(this);
        this.divRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.container !== this.props.container)
            this.setState({ container: this.props.container });
        if (prevProps.col !== this.props.col)
            this.setState({ col: this.props.col, tag: this.props.col.com.element ?? 'div' });
        if (prevState.showEditor !== this.props.col.showEditor)
            this.setState({ showEditor: this.props.col.showEditor });
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.resize);
        document.removeEventListener('mouseup', this.mouseUp);
    }

    hovered() {
        this.isFocused = true;
    }

    blurred() {
        this.isFocused = false;
    }

    htmlToBody() {
        var ele = document.querySelector('#' + this.state.idDiv + ' .wbp-editor-content-data');
        if (ele) {
            var col = this.state.col;
            col.body = ele.innerHTML;
            this.setState({ col: col });
        }
    }

    showToolbar() {
        if (this.hideToolbarTimer)
            clearTimeout(this.hideToolbarTimer);
        this.setSelectedColumn(this.state.col, this.state.container);
        //this.state.col.showEditor = true;
        //this.setState({ col: this.state.col, showEditor: true });
        this.state.col.showEditor = true;
        this.setState({ col: this.state.col, showEditor: true });
        this.setMainToolbarStatus(true);
        if (this.showComProps)
            this.showComProps();
    }

    hideToolbar() {
        this.hideToolbarTimer = setTimeout(() => {
            if (this.isFocused) {
                this.hideToolbar();
                return;
            }
            this.setSelectedColumn(null, null);
            this.setState({ showEditor: false });
            this.setMainToolbarStatus(false);
            this.hideToolbarTimer = null;
        }, 200);
        
        // Used with contentEdiable
        if (!this.state.col.com || this.state.col.com.editor === 'text')
            this.htmlToBody();

        this.props.snap();
        
    }

    getClass() {
        var r;
        if (this.state.col.parentId && !this.subContainer)
            r = 'wbp-col wbp-com wbp-col-child' + (this.state.col.className ? ' ' + this.state.col.className : '') + (this.state.col.size ? ' col' + this.state.colSz + '-' + this.state.col.size : '');
        else if (this.state.col.com && this.state.col.com.editor === 'text')
            r = "col" + this.state.colSz + " wbp-col wbp-com d-block" + (this.state.col.parentId ? ' wbp-col-child' : '') + (this.state.col.size ? ' col' + this.state.colSz + '-' + this.state.col.size : '');
        else if (this.state.col.com && this.state.col.com.isPlaceholder)
            r = "col" + this.state.colSz + " wbp-placeholder-col wbp-com d-block" + (this.state.col.parentId ? ' wbp-col-child' : '') + (this.state.col.size ? ' col' + this.state.colSz + '-' + this.state.col.size : '');
        else
            r = "col" + this.state.colSz + " wbp-col wbp-com" + (this.state.col.parentId ? ' wbp-col-child' : '') + (this.state.col.size ? ' col' + this.state.colSz + '-' + this.state.col.size : '');

        if (this.state.showEditor && !this.subContainer)
            r += ' wbp-editor-active';

        return r;
    }

    getComClass() {
        return (this.state.col.com && this.state.col.com.className ? ' ' + this.state.col.com.className : '');
    }


    onInput(e) {
        if (this.state.col.com && this.state.col.com.editor === 'text' && this.state.showEditor) {
            //var col = this.state.col;
            //todo
        }
    }

    showPropsModal() {
        this.setState({ showPropsModal: true });
    }

    onBodyChange(v) {
        this.state.col.body = v;
        this.setState({ col: this.state.col });
    }

    detectPosition(e) {        
        e.preventDefault();
        /*
        if(!this.state.showEditor)
            return;
        const thresh = 5;
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left;
        //var y = e.clientY - rect.top;
        if(this.state.resizeXPress)
            return;
        if(x + 12 > e.target.offsetWidth - thresh) {
            e.target.style.cursor = 'ew-resize';
            this.setState({ resizeX: true, resizePos: { x: e.clientX, y: e.clientY }, resizeInvert: false });
        }
        else if(x - 12 < 0 + thresh) {
            e.target.style.cursor = 'ew-resize';
            this.setState({ resizeX: true, resizePos: { x: e.clientX, y: e.clientY }, resizeInvert: true });
        }
        else {
            e.target.style.cursor = '';
            this.setState({ resizeX: false });
        }
        */
    }

    calcColSize() {
        if(!this.divRef.current)
            return 6;
        var parent = this.divRef.current.parentElement;
        var ratio = this.divRef.current.offsetWidth / parent.offsetWidth;
        var r = Math.round(ratio * 12);
        if(r < 1)
            return 1;
        else if(r > 12)
            return 12;
        return r;
    }

    resize(e) {
        if(!this.state.resizePos || !this.state.resizeXPress || !this.divRef.current)
            return;
        var d = this.state.resizePos.x - e.clientX;
        if((!this.state.resizeInvert && d > 80) || (this.state.resizeInvert && d < -80)) {
            if(!this.state.col.size)
                this.state.col.size = this.calcColSize();
            else if(this.state.col.size > 1)
                this.state.col.size = this.state.col.size-1;
            this.setState({ resizePos: {...this.state.resizePos, x: e.clientX}, col: this.state.col});
        }
        else if((!this.state.resizeInvert && d < -80) || (this.state.resizeInvert && d > 80)) {
            if(!this.state.col.size)
                this.state.col.size = this.calcColSize();
            else if(this.state.col.size < 12)
                this.state.col.size = this.state.col.size+1;
            this.setState({ resizePos: {...this.state.resizePos, x: e.clientX}, col: this.state.col});
        }
        document.body.style.cursor = 'ew-resize';
    }

    mouseDown(e) {
        if(this.state.resizeX && !this.state.resizeXPress) {
            this.setState({ resizeXPress: true });
            document.addEventListener('mousemove', this.resize);
            document.addEventListener('mouseup', this.mouseUp);
            document.body.style.cursor = 'ew-resize';
        }
    }

    mouseUp(e) {
        this.setState({ resizeXPress: false });
        document.removeEventListener('mousemove', this.resize);
        document.removeEventListener('mouseup', this.mouseUp);
        document.body.style.cursor = '';
        this.divRef.current.style.cursor = '';
    }

    render() {
        var pre = <Modal show={this.state.showPropsModal} onHide={() => this.setState({ showPropsModal: false })} size="xl">
            <form onSubmit={this.onSubmitLink}>
                <Modal.Header closeButton>
                    <Modal.Title><L>Properties</L></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PropertiesEditor col={this.state.col} />
                </Modal.Body>
                <Modal.Footer>
                    <small><L>Changes are automatically applied.</L></small>
                    <Button variant="secondary" onClick={() => this.setState({ showPropsModal: false })}>
                        <L>Close</L>
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>;
        
        if (this.state.col.com.editor === 'link') {
            pre = <LinkEditor col={this.state.col} setColState={this.setState} show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} removeColByID={this.props.removeColByID} onChange={() => this.setState({ col: this.state.col })} />;
        }

        var innerHTML;

        let style = {};
        let innerStyle = {};

        if (this.state.col.style) {
            for (var k in this.state.col.style) {
                style[k] = this.state.col.style[k];
                if(k === 'color')
                    innerStyle[k] = this.state.col.style[k];
            }
        }

        if(this.state.col.advancedStyle) {
            let s = this.state.col.advancedStyle.replace(/\n/g, '').replace(/\r/g, '').replace(/\t/g, '');
            s.split(';').forEach(v => {
                if(v) {
                    let p = v.split(':');
                    console.log('p',p);
                    if(p.length === 2) {
                        style[p[0]] = p[1];
                        if(p[0] === 'color')
                            innerStyle[p[0]] = p[1];
                    }
                }
            });
        }


        if (this.state.col.parentId) {

            innerHTML = this.state.col.body;

            if (this.state.col.com.editor === 'link') {
                innerHTML = <>{innerHTML} {!this.state.col.customComponent ? <span style={{ cursor: 'pointer' }} onClick={e => { e.preventDefault(); this.setState({ showModal: true }); }}><FontAwesomeIcon icon={fa.faPencil} /></span> : ''}</>;
            }
            if (this.state.col.com.innerElement === 'a') {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                innerHTML = <a className={this.state.col.com.innerClassName} style={style}>{innerHTML}</a>;
            }

            if (this.state.col.com.element === 'li')
                return <>{pre}<li id={this.state.idDiv} className={this.getComClass()} style={style} onClick={this.showToolbar} onBlur={this.hideToolbar} onMouseEnter={this.hovered} onMouseLeave={this.blurred} wbp-container-id={this.state.container.id} wbp-col-id={this.state.col.id} wbp-com-id={this.state.col.com ? this.state.col.com.id : 0}>{innerHTML}</li></>;
            else if (this.state.col.body && (this.subContainer || this.state.col.subContainer || this.state.container.subContainer))
                return <this.state.tag id={this.state.idDiv} className={this.getClass() + this.getComClass()} style={style} dangerouslySetInnerHTML={{ __html: this.state.col.body }}></this.state.tag>;
            else if(innerHTML)
                return <>{pre}<this.state.tag id={this.state.idDiv} className={this.getClass() + this.getComClass()} onClick={this.showToolbar} onBlur={this.hideToolbar} onMouseEnter={this.hovered} onMouseLeave={this.blurred} wbp-container-id={this.state.container.id} wbp-col-id={this.state.col.id} wbp-com-id={this.state.col.com ? this.state.col.com.id : 0}>{innerHTML}</this.state.tag></>;
        }

        innerHTML = this.state.col.containers.map(obj => <EditorContainer key={obj.id} parentId={this.state.col.id} parentCol={this.state.col} subContainer={this.subContainer} focusMove={this.focusMove} removeCol={this.removeCol} removeColByID={this.removeColByID} container={obj} setSelectedColumn={this.setSelectedColumn} setMainToolbarStatus={this.setMainToolbarStatus} />);
        var tag = this.state.col.com.innerElement;
        if (tag === 'ul') {
            if (this.state.col.com.editor === 'navbar')
                innerHTML = <EditorColNav style={innerStyle} linkStyle={innerStyle} />;
            innerHTML = <ul className={this.state.col.com.innerClassName}>{innerHTML}</ul>;
        }
        else if (tag === 'img') {
            if(this.state.col.image && this.state.col.image.url)
                innerHTML = <img src={this.state.col.image.url} className={this.state.col.com.innerClassName} alt="" />;
            else
                innerHTML = <img src={this.state.col.image ? 'data:' + this.state.col.image.fileType + ';base64,' + this.state.col.image.base64Content : '/img/placeholder.png'} className={this.state.col.com.innerClassName} alt="" />;
        }

        if (this.subContainer || this.state.col.subContainer || this.state.container.subContainer) {
            return <>
                {pre}
                <div id={this.state.idDiv} className={this.getClass()} wbp-container-id={this.state.container.id} wbp-col-id={this.state.col.id} wbp-com-id={this.state.col.com ? this.state.col.com.id : 0}>
                    <div className="wbp-editor-content">
                        {this.state.col.com.editor === 'text' ? <this.state.tag className={'wbp-editor-content-data' + (this.state.col.className ? ' ' + this.state.col.className : '') + (this.state.hover ? ' hover' : '')} style={style} dangerouslySetInnerHTML={this.state.col.containers.length < 2 ? { __html: this.state.col.body } : null} wbp-col-id={this.state.col.id}></this.state.tag>
                            : <this.state.tag className={'wbp-editor-content-data' + (this.state.col.com && this.state.col.com.editor === 'container' ? ' wbp-com-container' : '') + (this.state.col.className ? ' ' + this.state.col.className : '') + (this.state.hover ? ' hover' : '')} style={style} wbp-col-id={this.state.col.id}>
                                {innerHTML}
                            </this.state.tag>}
                    </div>
                </div>
            </>
        }

        return <>{pre}
            <div ref={this.divRef} id={this.state.idDiv} className={this.getClass()} onClick={this.showToolbar} onBlur={this.hideToolbar} onMouseEnter={this.hovered} onMouseLeave={this.blurred} wbp-container-id={this.state.container.id} wbp-col-id={this.state.col.id} wbp-com-id={this.state.col.com ? this.state.col.com.id : 0}
                onMouseMove={this.detectPosition}
                onMouseDown={this.mouseDown}
                onMouseUp={this.mouseUp}
            >
                {this.state.showEditor && !this.subContainer ? (<EditorColToolbar onBodyChange={this.onBodyChange} idDiv={this.state.idDiv} col={this.state.col} createCol={this.createCol} removeCol={this.removeCol} removeColByID={this.removeColByID} focusMove={this.focusMove} hideToolbar={this.hideToolbar} showComProps={this.showComProps} />) : ''}
                {!this.state.col.com.isPlaceholder ? <div className={'wbp-col-head' + (this.state.showEditor ? ' sel' : '')} onMouseDown={(e) => this.focusMove(e, this.state.idDiv, this.state.col, this.hideToolbar)}>
                    {this.state.col.com ? <div className="name move" title="Move"><FontAwesomeIcon icon={fa.faUpDownLeftRight} /> {this.state.col.com.name}</div> : ''}
                </div> : null}
                <div className="wbp-editor-content">
                    {this.state.col.com.editor === 'text' ? (<this.state.tag className={'wbp-editor-content-data' + (this.state.col.className ? ' ' + this.state.col.className : '') + (this.state.hover ? ' hover' : '')} style={style} dangerouslySetInnerHTML={{ __html: this.state.col.body }} onInput={this.onInput} contentEditable={this.state.col.com && this.state.col.com.editor === 'text' && this.state.showEditor} onMouseOver={e => this.setState({ hover: true })} onMouseOut={e => this.setState({ hover: false })} wbp-col-id={this.state.col.id}></this.state.tag>)
                        : <this.state.tag className={'wbp-editor-content-data row' + (this.state.col.com && this.state.col.com.editor === 'container' ? ' wbp-com-container' : '') + (this.state.col.className ? ' ' + this.state.col.className : '') + (this.state.hover ? ' hover' : '')} style={style} onMouseOver={e => this.setState({ hover: true })} onMouseOut={e => this.setState({ hover: false })} wbp-col-id={this.state.col.id}>
                            {innerHTML}
                        </this.state.tag>}
                </div>
            </div>
        </>;
    }
}