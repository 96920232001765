import React from 'react';
import { EditorContainer } from './EditorContainer';
import { v4 as uuidv4 } from 'uuid';
import EditorSidebar from './EditorSidebar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormInput from './FormInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { qreq } from '../shared/qrequest';
import { withParamsAndNavigate } from './WithParamsAndNavigate';
import { toast } from 'react-toastify';
import PropertiesEditor from './PropertiesEditor';
import EditorHeader from './EditorHeader';
import BaseCom from './BaseCom';
import LoadingModal from './LoadingModal';
import Auth from '../shared/Auth';
import { HTMLEditorModal } from './HTMLEditorModal';
import LauncherModal from './LauncherModal';
import DomainSearch from './DomainSearch';
import Alert from './Alert';
import Globals from '../shared/Globals';
import AIModal from './AIModal';
import L from './Lang';

export const EditorGlobal = {
    siteDomainID: 0,
    isTemplate: false
};


class Editor extends BaseCom {

    moveMS = 350;

    offsetX = 50;
    offsetY = 100;

    constructor(props) {
        super(props);
        this.defaultMetadata = {
            positionType: 'relative',
            id: 0,
            type: 'page',
            props: {
                style: {}
            }
        };
        this.state = {
            page: null,
            metadata: { ...this.defaultMetadata },
            containers: [],
            headerContainers: [],
            footerContainers: [],
            components: [],
            selectedContainer: null,
            selectedColumn: null,
            loaded: true,
            error: null,
            user: null,
            toolbarStatus: { disabled: false },
            showSaveModal: false,
            parentToggle: null,
            history: []
        };
        if (props.index)
            this.state.metadata.id = -1;
        this.rebuild = this.rebuild.bind(this);
        this.setSelectedContainer = this.setSelectedContainer.bind(this);
        this.setSelectedColumn = this.setSelectedColumn.bind(this);
        this.focusMove = this.focusMove.bind(this);
        this.focusMoveComponent = this.focusMoveComponent.bind(this);
        this.blurMove = this.blurMove.bind(this);
        this.move = this.move.bind(this);
        this.moveColToContainer = this.moveColToContainer.bind(this);
        this.getContainerByID = this.getContainerByID.bind(this);
        this.getColByID = this.getColByID.bind(this);
        this.setUser = this.setUser.bind(this);
        this.setToolbarStatus = this.setToolbarStatus.bind(this);
        this.snap = this.snap.bind(this);
        this.undo = this.undo.bind(this);
        this.redo = this.redo.bind(this);
        this.canUndo = this.canUndo.bind(this);
        this.canRedo = this.canRedo.bind(this);
        this.checkPayment = this.checkPayment.bind(this);
        this.controlFunctions = {
            insertRow: this.insertRow,
            removeRow: this.removeRow,
            insertCol: this.insertCol,
            removeCol: this.removeCol,
            showSave: this.showSave,
            createCol: this.createCol,
            showThemeProps: this.showThemeProps,
            publish: () => {
                var siteDomainID = this.state.metadata.siteDomainID;
                if(!siteDomainID) {
                    this.warn('Please save the page before publishing.');
                    return;
                }
                this.save(null, () => {
                    qreq.post('/api/editor/publish', { id: siteDomainID }, j => {
                        if(j.errorCode)
                            this.alert(j.errorMessage);
                        else {
                            if(!this.state.payment)
                                this.controlFunctions.showDomainSearch();
                            else
                                this.success('Site has been published.');
                        }
                    });  
                });
            },
            purchase: () => {
                if(this.state.user?.isInternal) {
                    this.alert('Internal users cannot purchase.');
                    return;
                }
                var siteDomainID = this.state.metadata.siteDomainID;
                if(!siteDomainID) {
                    this.warn('Please save the page before purchasing.');
                    return;
                }
                this.save(null, () => {
                    this.controlFunctions.showDomainSearch(); 
                });
            },
            showDomainSearch: () => {
                if(this.state.user?.isInternal) {
                    this.alert('Internal users cannot purchase domains.');
                    return;
                }
                this.checkPayment(p => {
                    qreq.get('/api/domain/register/check?siteDomainID=' + this.state.metadata.siteDomainID, j => {
                        if (j.errorCode === 9)
                            this.setState({ showDomainSearch: true });
                        else if (j.errorCode >= 100)
                            this.warn(j.errorMessage);
                        else if (j.errorCode)
                            this.alert(j.errorMessage);
                        else {
                            this.success('Site has been published.');
                        }
                    });
                });       
            },
            snap: this.snap,
            undo: this.undo,
            redo: this.redo,
            canUndo: this.canUndo,
            canRedo: this.canRedo,
            checkPayment: this.checkPayment
        };
        this.createCol = this.createCol.bind(this);
        this.insertCom = this.insertCom.bind(this);
        this.removeColByID = this.removeColByID.bind(this);
        this.save = this.save.bind(this);
        this.load = this.load.bind(this);
        this.loadPage = this.loadPage.bind(this);
        this.setSidebarExpanded = this.setSidebarExpanded.bind(this);
        this.onSelectType = this.onSelectType.bind(this);
        this.reloadCustomComponents = this.reloadCustomComponents.bind(this);
        this.setContainersAsSub = this.setContainersAsSub.bind(this);
        this.onClick = this.onClick.bind(this);
        this.getCSS = this.getCSS.bind(this);
        this.processExtraCSS = this.processExtraCSS.bind(this);
        this.setMetadata = this.setMetadata.bind(this);
        this.setComPropsToggle = this.setComPropsToggle.bind(this);
        this.showComProps = this.showComProps.bind(this);
        this.loadComponents = this.loadComponents.bind(this);
        for (var k in this.controlFunctions) {
            this.controlFunctions[k] = this.controlFunctions[k].bind(this);
        }
        this.checkLauncher = this.checkLauncher.bind(this);
        this.moveFloaterRef = React.createRef();
        this.showMoveFloater = this.showMoveFloater.bind(this);
        this.generatePageIdent = this.generatePageIdent.bind(this);
        this.onNavSave = this.onNavSave.bind(this);
        this.pageIdentChanged = this.pageIdentChanged.bind(this);
    }


    componentDidMount() {
        document.addEventListener('click', this.onClick);
        Auth.getLogin(u => {
            this.setState({ user: u });
            if (u) {
                this.load();
            }
            else
                this.props.navigate('/login');
        });
        EditorGlobal.isTemplate = this.props.template;
        EditorGlobal.siteDomainID = this.props.templateID;
        Globals.set('isTemplate', this.props.template);
        Globals.set('templateID', this.props.templateID);
        this.checkPayment();
    }

    componentWillUnmount() {
        if (this.moveInterval)
            clearInterval(this.moveInterval);
        document.removeEventListener('click', this.onClick);
        EditorGlobal.isTemplate = null;
        EditorGlobal.siteDomainID = null;
        Globals.set('isTemplate', null);
        Globals.set('templateID', null);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params && this.props.params && prevProps.params.id !== this.props.params.id) {
            var metadata = { ...this.defaultMetadata };
            metadata.id = this.props.params.id ?? 0;
            this.setState({ metadata: metadata });
            this.load();
        }
    }

    onNavSave(ver) {
        this.setState({ navVer: ver })
    }

    snap() {
        setTimeout(() => {
            var hist = [...this.state.history];
            var current = hist.find(a => a.current === true);
            if(!current || current.containersJson !== JSON.stringify(this.state.containers)
                || current.headerContainersJson !== JSON.stringify(this.state.headerContainers)
                || current.footerContainersJson !== JSON.stringify(this.state.footerContainers)) {
                if (current)
                    hist.splice(0, hist.indexOf(current));
                hist.forEach(i => {
                    i.current = false;
                });
                hist.splice(0, 0, { containersJson: JSON.stringify(this.state.containers),
                    headerContainersJson: JSON.stringify(this.state.headerContainers),
                    footerContainersJson: JSON.stringify(this.state.footerContainers),
                    current: true });
                this.setState({ history: hist });
            }
            
        }, 100);
    }

    load() {
        qreq.get('/api/editor/domain' + (this.props.params.templateID ? '?siteDomainID=' + this.props.params.templateID : ''), j => {
            if (!j.item)
                this.props.navigate('/login', { replace: true });
            else {
                this.setState({ domain: j.item });
                EditorGlobal.siteDomainID = j.item.id;
        
                if (this.props.index) {
                    qreq.get('/api/editor/page/index' + (this.props.params.templateID ? '?siteDomainID=' + this.props.params.templateID : ''), j => {
                        if (j.item)
                            this.props.navigate(this.props.params.templateID ? (this.props.template ? '/editor/template/' + this.props.params.templateID : '/editor/site/' + this.props.params.templateID) + '/page/' + j.item.id : '/editor/page/' + j.item.id, { replace: true });
                        else {
                            if (j.errorCode !== 3)
                                this.alert(j.errorMessage);
                            this.loadComponents();
                            this.checkLauncher();
                            if(!this.props.template)
                                this.checkPayment();
                        }
                    });
                }
                else {
                    this.loadComponents();
                }
            }
        });
    }

    checkPayment(callback) {
        this.setState({ publishing: true });
        qreq.get('/api/domain/payment/check?siteDomainID=' + this.props.params.templateID, j => {
            if(j.errorCode === 0)
                this.setState({ payment: j.item });
            else
                this.setState({ payment: null });
            callback?.(j);
            this.setState({ publishing: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ publishing: false });
        });
    }

    checkLauncher() {
        if (!this.props.template) {
            qreq.get('/api/editor/page/list' + (this.props.params.templateID ? '?siteDomainID=' + this.props.params.templateID : ''), j => {
                if (j.list && j.list.length === 0) {
                    this.setState({ showLauncherModal: true });
                }
                this.setState({ loaded: true });
            }, () => {
                this.setState({ loaded: true });
            });
        }
        else
            this.setState({ loaded: true });
    }

    loadComponents() {
        qreq.get('/api/editor/component/list' + (this.props.params.templateID ? '?siteDomainID=' + this.props.params.templateID : ''), j => {
            if (j.list) {
                this.setState({ components: j.list, componentsLoaded: true });
                this.loadPage(this.props.params.id ?? 0);
            }
        });
    }

    setMetadata(v) {
        this.setState({ metadata: v });
    }

    onClick(e) {
        var [cur, scannedClassName] = this.scanNodeForDrop(e.target);
        if (!scannedClassName) {
            this.setSelectedColumn(null);
        }
    }

    setSidebarExpanded(b) {
        this.setState({ sidebarExpanded: b });
    }

    reloadCustomComponents(containers) {
        containers.forEach(i => {
            i.cols.forEach(j => {
                if (j.com && j.com.sitePageID) {
                    var com = this.getComponentById(j.com.id);
                    if (com) {
                        j.com = com;
                        j.containers = JSON.parse(com.jsonData);
                    }
                    this.setContainersAsSub(j.containers);
                }
                if (!j.childStyle || Array.isArray(j.childStyle))
                    j.childStyle = {};
            });
        });
    }

    loadPage(id, reload) {
        if (id !== 0) {
            if(!reload)
                this.setState({ pageLoaded: false });
            qreq.get('/api/editor/page/get/' + id, j => {
                if (j.item) {
                    var containers = JSON.parse(j.item.jsonData);
                    j.item.props = JSON.parse(j.item.propsJsonData);
                    if (!containers)
                        containers = [];
                    else
                        this.reloadCustomComponents(containers);
                    var headerContainers = [];
                    if (j.item.headerJsonData) {
                        headerContainers = JSON.parse(j.item.headerJsonData);
                        this.reloadCustomComponents(headerContainers);
                    }
                    var footerContainers = [];
                    if (j.item.footerJsonData) {
                        footerContainers = JSON.parse(j.item.footerJsonData);
                        this.reloadCustomComponents(footerContainers);
                    }
                    this.setState({ metadata: j.item, containers: containers, headerContainers: headerContainers, footerContainers: footerContainers, pageLoaded: true });
                    this.snap();
                }
                else {
                    if (j.errorCode !== 5 && j.errorMessage)
                        this.alert(j.errorMessage);
                    var metadata = { ...this.state.metadata };
                    metadata.id = 0;
                    this.setState({ metadata: metadata, containers: [], pageLoaded: true });
                    this.snap();
                }
            }, e => {
                console.log(e);
                this.unkownErrorCallback();
                this.setState({ pageLoaded: true });
            });
        }
        else {
            qreq.get('/api/editor/get/header-footer' + (this.props.params.templateID ? '?siteDomainID=' + this.props.params.templateID : ''), j => {
                if (j.item) {
                    var headerContainers = [];
                    if (j.item.headerJsonData)
                        headerContainers = JSON.parse(j.item.headerJsonData);
                    var footerContainers = [];
                    if (j.item.footerJsonData)
                        footerContainers = JSON.parse(j.item.footerJsonData);
                    this.setState({ headerContainers: headerContainers, footerContainers: footerContainers });
                }
                this.setState({ pageLoaded: true, containers: [], metadata: { ...this.defaultMetadata } });
            });
        }
    }

    getComponentById(id) {
        var r = null;
        this.state.components.forEach(i => {
            if (i.id === id)
                r = i;
        })
        return r;
    }

    getComponentByIdent(ident) {
        var r = null;
        this.state.components.forEach(i => {
            if (i.ident === ident)
                r = i;
        })
        return r;
    }


    rebuild(containers, propName) {
        if (!propName)
            propName = 'containers';
        setTimeout(() => {
            if (!containers)
                containers = this.state[propName];

            if (containers.length > 0) {
                var toRemove = 0;

                for (var n = 0; n < containers.length; n++) {
                    if (containers[n].cols.length === 0)
                        toRemove++;
                    else
                        break;
                }

                if (toRemove > 0)
                    containers.splice(0, toRemove);

                toRemove = 0;

                for (var n = containers.length - 1; n >= 0; n--) {
                    if (containers[n].cols.length === 0)
                        toRemove++;
                    else
                        break;
                }

                if (toRemove > 0)
                    containers.splice(containers.length - toRemove, toRemove);
            }
            this.setState({ [propName]: containers });
        }, 100);
    }


    showSave(callback) {
        if (!this.state.metadata.type)
            this.setState({ metadata: { ...this.state.metadata, type: 'page', index: this.state.metadata.ident === 'index' } });
        this.setState({ showSaveModal: true, saveCallback: callback });
    }

    save(e, callback) {
        if (e)
            e.preventDefault();

        var data = { ...this.state.metadata };

        var containers = [...this.state.containers];

        data.jsonData = JSON.stringify(containers);
        data.propsJsonData = JSON.stringify(data.props);
        data.domainPropsJsonData = JSON.stringify(data.props.domain);

        if (data.type === 'component')
            data.isComponent = true;

        if (this.props.params.templateID) {
            data.isTemplate = this.props.template;
            data.siteDomainID = this.props.params.templateID;
        }

        if (data.isTemplate && !data.siteDomainID) {
            this.alert('No template selected.');
            return;
        }

        data.headerJsonData = JSON.stringify([...this.state.headerContainers]);
        data.footerJsonData = JSON.stringify([...this.state.footerContainers]);


        this.setState({ saving: true, editIdent: false });
        qreq.post('/api/editor/save', data, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else if (j.errorCode === 0) {
                data.id = j.item.id;
                this.setState({ showSaveModal: false, metadata: data });
                if(!callback)
                    this.success((data.isComponent ? 'Component' : 'Page') + ' has been saved.');
                if (this.props.params.id !== j.item.id) {
                    this.props.navigate(this.props.params.templateID ? '/editor/template/' + this.props.params.templateID + '/page/' + j.item.id : '/editor/page/' + j.item.id);
                    this.loadPage(j.item.id, true);
                }
                callback?.();
            }
            this.setState({ saving: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ saving: false });
        });
    }

    setSelectedContainer(container) {
        this.setState({ selectedContainer: container });
    }

    setSelectedColumn(col, container) {
        this.state.containers.forEach(i => {
            i.cols.forEach(j => {
                if (j !== col)
                    j.showEditor = false;
            });
        });
        this.state.headerContainers.forEach(i => {
            i.cols.forEach(j => {
                if (j !== col)
                    j.showEditor = false;
            });
        });
        this.state.footerContainers.forEach(i => {
            i.cols.forEach(j => {
                if (j !== col)
                    j.showEditor = false;
            });
        });
        this.setState({ containers: this.state.containers, headerContainers: this.state.headerContainers, footerContainers: this.state.footerContainers, selectedColumn: col, selectedContainer: container });
    }

    setContainersAsSub(containers) {
        if (!containers)
            return;
        containers.forEach(i => {
            i.subContainer = true;
            i.customComponent = true;
            i.cols.forEach(j => {
                j.subContainer = true;
                j.customComponent = true;
                this.setContainersAsSub(j.containers);
            });
        });
    }

    showThemeProps() {
        this.setState({ showThemePropsModal: true });
    }

    createCol(comId, body, size, extraProps) {
        var com;
        if (typeof (comId) === 'string')
            com = this.getComponentByIdent(comId);
        else
            com = this.getComponentById(comId);
        var col = {
            id: uuidv4(),
            body: body ?? (com && com.body ? com.body : com && com.isPlaceholder ? '' : 'Contents here...'),
            com: com,
            style: com && com.style ? com.style : {},
            childStyle: com && com.childStyle ? com.childStyle : {},
            className: com && com.className ? com.className : null,
            containers: [{
                id: uuidv4(),
                cols: []
            }],
            size: size
        };

        if(extraProps)
            col = {...col, ...extraProps};


        if (com && com.subCom) {
            col.subContainer = true;
            col.containers[0].subContainer = true;
        }

        if (com && com.sitePageID) {
            col.containers = JSON.parse(com.jsonData);
            this.setContainersAsSub(col.containers);
        }

        return col;
    }

    insertCom(com, section, extraProps) {
        if (!section)
            section = 'containers';
        var containers = [...this.state[section]];
        containers.push({
            id: uuidv4(),
            cols: [this.createCol(com.id, com.body, null, extraProps)],
            sectionStyle: {}
        });
        this.setState({ [section]: containers });
        this.snap();
    }

    insertRow(section) {
        if (!section)
            section = 'containers';
        var containers = [...this.state[section]];
        var row = {
            id: uuidv4(),
            cols: [this.createCol('free-text')],
            sectionStyle: {}
        };
        containers.push(row);
        this.setState({ [section]: containers });
        this.snap();
        return row;
    }

    removeRow() {
        var containers = this.state.containers;
        var selectedContainer = this.state.selectedContainer;
        if (!selectedContainer)
            return;

        containers.splice(containers.indexOf(selectedContainer), 1);
        this.setState({ containers: this.state.containers });
        this.snap();
    }

    insertCol(section) {
        if (!section)
            section = 'containers';
        var selectedContainer = this.state.selectedContainer;
        if (!selectedContainer)
            return;

        selectedContainer.cols.push(this.createCol('free-text'));

        this.setState({ [section]: this.state.containers });

        this.rebuild(null, section);
        this.snap();
    }

    removeCol() {
        var selectedContainer = this.state.selectedContainer;
        var selectedColumn = this.state.selectedColumn;
        if (!selectedContainer || !selectedColumn)
            return;

        selectedContainer.cols.splice(selectedContainer.cols.indexOf(selectedColumn), 1);
        this.setState({ containers: this.state.containers });
        this.snap();
    }

    getContainerByID(id, containers) {
        if (!containers)
            containers = this.state.containers;

        for (var n = 0; n < containers.length; n++) {
            if (containers[n].id === id)
                return containers[n];
            /*
            else {
                if (containers[n].cols.length !== 0) {
                    for (var i = 0; containers[n].cols.length; i++) {
                        if (containers[n].cols[i].containers) {
                            var r = this.getContainerByID(id, containers[n].cols[i].containers)
                            if (r)
                                return r;
                        }
                    }
                }
            }
            */
        }
        return null;
    }


    getColByID(id, containers) {
        if (!containers)
            containers = this.state.containers;
        for (var n = 0; n < containers.length; n++) {
            var cont = containers[n];
            for (var i = 0; i < cont.cols.length; i++) {
                var col = cont.cols[i];
                if (col.id === id)
                    return col;
                else if (col.containers.length !== 0) {
                    var r = this.getColByID(id, col.containers);
                    if (r)
                        return r;
                }

            }
        }
        return null;
    }

    removeColByID(id, containers) {
        if (!containers)
            containers = [...this.state.containers];
        for (var n = 0; n < containers.length; n++) {
            var cont = containers[n];
            if (cont.cols.length !== 0) {
                for (var i = 0; i < cont.cols.length; i++) {
                    var col = cont.cols[i];
                    if (col.id === id) {
                        cont.cols.splice(i, 1, this.createCol('placeholder'));
                        this.setState({ containers: this.state.containers });
                        return true;
                    }
                    else if (col.containers.length !== 0) {
                        if (this.removeColByID(id, col.containers))
                            return true;
                    }
                }
            }
        }
        return false;
    }

    scanNodeForDrop(dropEle, excludeInline) {
        var scannedClassName = null;
        var cur = dropEle;
        if (!cur)
            return [null, null];
        var count = 0;
        do {
            if (count >= 1000)
                break;
            if (cur.classList) {
                if (cur.classList.contains('wbp-container') && !excludeInline)
                    scannedClassName = 'wbp-container';
                else if (cur.classList.contains('wbp-placeholder-col') && !excludeInline)
                    scannedClassName = 'wbp-placeholder-col';
                else if (cur.classList.contains('wbp-col') && !cur.classList.contains('wbp-col-child') && !excludeInline)
                    scannedClassName = 'wbp-col';
                else if (cur.classList.contains('wbp-editor-wrapper'))
                    scannedClassName = 'wbp-editor-wrapper';
                else if (cur.classList.contains('wbp-editor-header'))
                    scannedClassName = 'wbp-editor-header';
                else if (cur.classList.contains('wbp-editor-footer'))
                    scannedClassName = 'wbp-editor-footer';
                else if (cur.classList.contains('wbp-com-container') && !excludeInline)
                    scannedClassName = 'wbp-com-container';
            }
            if (scannedClassName)
                break;
            count++;
        } while (cur = cur.parentNode);

        return [cur, scannedClassName];
    }

    getContainerSectionByElement(ele) {
        var [cur, scannedClassName] = this.scanNodeForDrop(ele, true);
        if (scannedClassName === 'wbp-editor-wrapper')
            return 'containers';
        else if (scannedClassName === 'wbp-editor-header')
            return 'headerContainers';
        else if (scannedClassName === 'wbp-editor-footer')
            return 'footerContainers';
    }

    clearCurrentHover(cur) {
        if (this.currentHover && this.currentHover !== cur) {
            this.currentHover.classList.remove('wbp-move-hover');
            this.currentHover.classList.remove('wbp-move-hover-after');
            this.currentHover = null;
        }
    }

    moveColToContainer(col, container) {
        var oldContainer = null;
        this.state.containers.forEach(i => {
            i.cols.forEach(j => {
                if (j === col)
                    oldContainer = i;
            });
        });
        if (oldContainer)
            oldContainer.cols.splice(oldContainer.cols.indexOf(col), 1);
        oldContainer = null;
        this.state.headerContainers.forEach(i => {
            i.cols.forEach(j => {
                if (j === col)
                    oldContainer = i;
            });
        });
        if (oldContainer)
            oldContainer.cols.splice(oldContainer.cols.indexOf(col), 1);
        oldContainer = null;
        this.state.footerContainers.forEach(i => {
            i.cols.forEach(j => {
                if (j === col)
                    oldContainer = i;
            });
        });
        if (oldContainer)
            oldContainer.cols.splice(oldContainer.cols.indexOf(col), 1);
        if (container)
            container.push(col);
    }

    showMoveFloater(ele, pos) {
        if (!this.moveFloaterRef.current)
            return;
        if (pos === 'reset') {
            this.moveFloaterRef.current.style.display = 'none';
            return;
        }
        var rect = ele.getBoundingClientRect();


        if (pos === 'left') {
            this.moveFloaterRef.current.style.top = (rect.top + window.scrollY) + 'px';
            this.moveFloaterRef.current.style.left = (rect.left + window.scrollX) + 'px';
            this.moveFloaterRef.current.style.display = 'block';
        }
        else if (pos === 'right') {
            this.moveFloaterRef.current.style.top = (rect.top + window.scrollY) + 'px';
            this.moveFloaterRef.current.style.left = (rect.right + window.scrollX) + 'px';
            this.moveFloaterRef.current.style.display = 'block';
        }

    }

    focusMoveComponent(e, obj) {
        e.preventDefault();
        if(!obj) return;

        var ele = document.createElement('div');
        ele.className = 'c-com-drag p-3 border-1 rounded bg-light';
        ele.style.zIndex = 1001;
        ele.style.cursor = 'move';
        ele.style.visibility = 'hidden';
        ele.innerHTML = obj.name;

        var bodyElement = document.querySelector('body')
        var originalParent = bodyElement;

        bodyElement.appendChild(ele);

        document.body.style.cursor = 'move';

        document.onmouseup = (e) => {
            clearInterval(this.moveInterval);
            this.showMoveFloater(null, 'reset');
            var originalParent = ele.parentNode;
            var nextElement = ele.nextSibling;
            var dropEle = document.elementFromPoint(e.clientX, e.clientY);
            document.onmouseup = null;
            document.onmousemove = null;

            var [cur, scannedClassName] = this.scanNodeForDrop(dropEle);
            this.clearCurrentHover();

            ele.className = 'wbp-com wbp-col';
            ele.style.visibility = 'visible';
            ele.style.position = 'relative';
            ele.style.left = 'auto';
            ele.style.top = 'auto';
            ele.setAttribute('wbp-com-id', obj.id);
            ele.innerHTML = obj.body ?? 'Contents here...';

            bodyElement.removeChild(ele);

            if (!scannedClassName)
                return;

            document.body.style.cursor = 'default';


            var contSection, cont, idx;

            if (obj.isSection) {
                if (scannedClassName === 'wbp-com-container' || scannedClassName === 'wbp-container' || scannedClassName === 'wbp-col') {
                    contSection = this.getContainerSectionByElement(cur);
                    if (contSection) {
                        cont = this.getContainerByID(cur.getAttribute('wbp-container-id'), this.state[contSection]);
                        var conts = [...this.state[contSection]];
                        idx = conts.findIndex(a => a.id === cont.id);
                        if (idx >= 0) {
                            var newCols = [];
                            for (var n = 0; n < obj.columnCount; n++) {
                                newCols.push(this.createCol('placeholder'));
                            }
                            conts.splice(idx, 0, {
                                id: uuidv4(),
                                cols: newCols,
                                sectionStyle: {}
                                //, rowCols: obj.columnCount
                            });
                            this.setState({ [contSection]: conts });
                        }
                    }
                }
                //todo
                return;
            }

            if (scannedClassName === 'wbp-container') {
                contSection = this.getContainerSectionByElement(cur);
                if (contSection) {

                    cont = this.getContainerByID(cur.getAttribute('wbp-container-id'), this.state[contSection]);
                    if (cont)
                        cont.cols.push(this.createCol(obj.id, obj.body ?? 'Contents here...', null, obj.extraProps));
                }
            }
            else if (scannedClassName === 'wbp-col') {
                contSection = this.getContainerSectionByElement(cur);
                if (contSection) {

                    var tcol = this.getColByID(cur.getAttribute('wbp-col-id'), this.state[contSection]);
                    cont = this.getContainerByID(cur.getAttribute('wbp-container-id'), this.state[contSection]);
                    if (cont) {
                        var col = this.createCol(obj.id, obj.body ?? 'Contents here...', null, obj.extraProps);
                        for (let n = 0; n < cont.cols.length; n++) {
                            if (cont.cols[n] === tcol) {
                                if (this.currentHoverAfter)
                                    cont.cols.splice(n + 1, 0, col);
                                else
                                    cont.cols.splice(n, 0, col);
                                break;
                            }
                        }
                    }
                }
            }
            else if (scannedClassName === 'wbp-editor-wrapper') {
                this.insertCom(obj, null, obj.extraProps);
            }
            else if (scannedClassName === 'wbp-editor-header') {
                this.insertCom(obj, 'headerContainers', obj.extraProps);
            }
            else if (scannedClassName === 'wbp-editor-footer') {
                this.insertCom(obj, 'footerContainers', obj.extraProps);
            }
            else if (scannedClassName === 'wbp-com-container') {
                var colId = cur.getAttribute('wbp-col-id');
                if (colId) {
                    col = this.getColByID(colId);
                    var newCol = this.createCol(obj.id, obj.body, null, obj.extraProps);
                    newCol.parentId = colId;
                    cont = {
                        id: uuidv4(), cols: [],
                        sectionStyle: {}
                    };
                    if (col.containers.length === 0)
                        col.containers.push(cont);
                    else
                        cont = col.containers[0];

                    cont.cols.push(newCol);
                    this.setState({ containers: this.state.containers });
                }

            }
            else if (scannedClassName === 'wbp-placeholder-col') {
                contSection = this.getContainerSectionByElement(cur);
                if (contSection) {
                    cont = this.getContainerByID(cur.getAttribute('wbp-container-id'), this.state[contSection]);
                    if (cont) {
                        idx = cont.cols.findIndex(a => a.id === cur.getAttribute('wbp-col-id'));
                        var pholder = cont.cols[idx];
                        if (idx >= 0) {
                            cont.cols.splice(idx, 1, this.createCol(obj.id, obj.body ?? 'Contents here...', pholder.size, obj.extraProps));
                        }
                    }
                }
            }

            this.rebuild();
            this.rebuild(null, 'headerContainers');
            this.rebuild(null, 'footerContainers');
        };

        var event = null;

        document.onmousemove = (e) => {
            var l = e.pageX;
            var t = e.pageY;
            event = e;
        }

        this.moveInterval = setInterval(() => {
            if (!event)
                return;

            ele.style.visibility = 'hidden';
            //ele.style.position = 'absolute';
            //ele.style.left = l + 'px';
            //ele.style.top = t + 'px';

            var hoverEle = document.elementFromPoint(event.clientX, event.clientY);

            if (!hoverEle)
                return;

            var [cur, scannedClassName] = this.scanNodeForDrop(hoverEle);
            this.clearCurrentHover(cur);


            if (scannedClassName === 'wbp-container' || scannedClassName === 'wbp-col') {
                var rect = cur.getBoundingClientRect();
                var w = rect.width;
                var hw = w / 2;
                var ex = event.clientX - rect.left;
                //var ey = e.clientY - rect.top;

                if (ex > hw) {
                    this.showMoveFloater(cur, 'right');
                    this.currentHoverAfter = true;
                }
                else {
                    this.showMoveFloater(cur, 'left');
                    this.currentHoverAfter = false;
                }
                this.currentHover = cur;
            }
            else if (scannedClassName === 'wbp-editor-wrapper' || scannedClassName === 'wbp-editor-header' || scannedClassName === 'wbp-editor-footer') {
                if (!cur.classList.contains('wbp-move-hover'))
                    cur.classList.add('wbp-move-hover');
                this.currentHover = cur;
            }
            else if (scannedClassName === 'wbp-placeholder-col') {
                if (!cur.classList.contains('wbp-move-hover'))
                    cur.classList.add('wbp-move-hover');
                this.currentHover = cur;
            }

        }, this.moveMS);

    }

    focusMove(e, idDiv, col, callback) {
        e.preventDefault();

        var wrapper = document.querySelector('.wbp-editor-wrapper');

        wrapper.classList.add('dragging');

        document.body.style.cursor = 'move';

        var ele = document.getElementById(idDiv);
        if (!ele)
            return;
        ele.style.opacity = '0.5';

        var originalLeft = ele.style.left;
        var originalTop = ele.style.top;
        var originalWidth = ele.style.width;

        var moved = false;

        document.onmouseup = (e) => {
            clearInterval(this.moveInterval);
            this.showMoveFloater(null, 'reset');
            var originalParent = ele.parentNode;
            var nextElement = ele.nextSibling;

            ele.style.visibility = 'hidden';
            var dropEle = document.elementFromPoint(e.clientX, e.clientY);
            document.onmouseup = null;
            document.onmousemove = null;


            var [cur, scannedClassName] = this.scanNodeForDrop(dropEle);
            this.clearCurrentHover();



            ele.style.visibility = 'visible';
            ele.style.position = 'relative';
            ele.style.left = originalLeft;
            ele.style.top = originalTop;
            ele.style.width = originalWidth;
            ele.style.opacity = '1';



            wrapper.classList.remove('dragging');
            document.body.style.cursor = 'default';


            if (!scannedClassName)
                return;
            if (ele.id === cur.id)
                return;
            var cont;

            if (scannedClassName === 'wbp-col') {
                if (col.id === cur.getAttribute('wbp-col-id'))
                    return;
            }
            else if (scannedClassName === 'wbp-container') {
                cont = this.getContainerByID(cur.getAttribute('wbp-container-id'));
                if (!cont)
                    return;
            }

            this.removeColByID(col.id);
            this.removeColByID(col.id, [...this.state.headerContainers]);
            this.removeColByID(col.id, [...this.state.footerContainers]);

            var contSection, row, tcol;

            if (scannedClassName === 'wbp-container') {
                contSection = this.getContainerSectionByElement(cur);
                if (contSection) {
                    cont = this.getContainerByID(cur.getAttribute('wbp-container-id'), this.state[contSection]);
                    if (cont) {
                        col.size = null;
                        cont.cols.push(col);
                    }
                }
            }
            else if (scannedClassName === 'wbp-col') {
                contSection = this.getContainerSectionByElement(cur);
                if (contSection) {
                    col.size = null;
                    tcol = this.getColByID(cur.getAttribute('wbp-col-id'), this.state[contSection]);
                    cont = this.getContainerByID(cur.getAttribute('wbp-container-id'), this.state[contSection]);
                    if (cont) {
                        for (var n = 0; n < cont.cols.length; n++) {
                            if (cont.cols[n] === tcol) {
                                if (this.currentHoverAfter)
                                    cont.cols.splice(n + 1, 0, col);
                                else
                                    cont.cols.splice(n, 0, col);
                                break;
                            }
                        }
                    }
                }
            }
            else if (scannedClassName === 'wbp-editor-wrapper') {
                row = this.controlFunctions.insertRow();
                row.cols = [col];
            }
            else if (scannedClassName === 'wbp-editor-header') {
                row = this.controlFunctions.insertRow('headerContainers');
                row.cols = [col];
            }
            else if (scannedClassName === 'wbp-editor-footer') {
                row = this.controlFunctions.insertRow('footerContainers');
                row.cols = [col];
            }
            else if (scannedClassName === 'wbp-placeholder-col') {
                contSection = this.getContainerSectionByElement(cur);
                if (contSection) {
                    cont = this.getContainerByID(cur.getAttribute('wbp-container-id'), this.state[contSection]);
                    if (cont) {
                        var idx = cont.cols.findIndex(a => a.id === cur.getAttribute('wbp-col-id'));
                        var pholder = cont.cols[idx];
                        if (idx >= 0) {
                            col.size = pholder.size;
                            cont.cols.splice(idx, 1, col);
                        }
                    }
                }
            }
            else {
                if (nextElement)
                    originalParent.insertBefore(ele, nextElement);
                else
                    originalParent.appendChild(ele);
            }


            this.rebuild();
            this.rebuild(null, 'headerContainers');
            this.rebuild(null, 'footerContainers');
            this.setComPropsToggle(null);
            this.snap();
            if (callback)
                callback();
        };

        var event = null;

        document.onmousemove = (e) => {
            var l = e.pageX - this.offsetX;
            var t = e.pageY - this.offsetY;
            //ele.style.position = 'absolute';
            //ele.style.left = l + 'px';
            //ele.style.top = t + 'px';
            //ele.style.width = '30vw';
            event = e;



        };

        this.moveInterval = setInterval(() => {
            if (!event)
                return;

            var hoverEle = document.elementFromPoint(event.clientX, event.clientY);

            if (!hoverEle)
                return;

            var [cur, scannedClassName] = this.scanNodeForDrop(hoverEle);
            if (!cur)
                return;
            this.clearCurrentHover(cur);

            if (idDiv === cur.id)
                return;

            if (scannedClassName === 'wbp-container' || scannedClassName === 'wbp-col') {
                var rect = cur.getBoundingClientRect();
                var w = rect.width;
                var hw = w / 2;
                var ex = event.clientX - rect.left;
                //var ey = e.clientY - rect.top;


                if (ex > hw) {
                    this.showMoveFloater(cur, 'right');
                    this.currentHoverAfter = true;
                }
                else {
                    this.showMoveFloater(cur, 'left');
                    this.currentHoverAfter = false;
                }
                this.currentHover = cur;
            }
            else if (scannedClassName === 'wbp-editor-wrapper' || scannedClassName === 'wbp-editor-header' || scannedClassName === 'wbp-editor-footer') {
                if (!cur.classList.contains('wbp-move-hover'))
                    cur.classList.add('wbp-move-hover');
                this.currentHover = cur;
            }
            else if (scannedClassName === 'wbp-placeholder-col') {
                if (!cur.classList.contains('wbp-move-hover'))
                    cur.classList.add('wbp-move-hover');
                this.currentHover = cur;
            }

            moved = true;
        }, this.moveMS);

    }

    blurMove(e) {

    }

    move(e) {

    }

    setUser(user) {
        this.setState({ user: user });
    }

    setToolbarStatus(disabled) {
        var toolbarStatus = { ...this.state.toolbarStatus };
        toolbarStatus.disabled = disabled;
        this.setState({ toolbarStatus: toolbarStatus });
    }

    onSelectType(v) {
        var metadata = { ...this.state.metadata };
        metadata.type = v;
        this.setState({ metadata: metadata });
    }

    toLowerDash(s) {
        var r = '';
        for (var n = 0; n < s.length; n++) {
            if (s[n] !== s[n].toLowerCase())
                r += '-' + s[n].toLowerCase();
            else
                r += s[n];
        }
        return r;
    }

    processExtraCSS(css) {
        var r = '';
        var regex = /(.*){([\s\S]*?)}/g;
        var matches = [...css.matchAll(regex)];

        if (matches) {
            matches.forEach(i => {
                var kp = i[1].split(',');
                for (var n = 0; n < kp.length; n++) {
                    kp[n] = '.wbp-editor ' + kp[n];
                }
                r += kp.join(', ') + ' {' + i[2] + '} ';
            });
        }
        return r;
    }

    getCSS() {
        var css = '';
        if (this.state.metadata.props.css)
            css += this.processExtraCSS(this.state.metadata.props.css);
        if (this.state.metadata.props.domain && this.state.metadata.props.domain.css)
            css += this.processExtraCSS(this.state.metadata.props.domain.css);

        css = '.wbp-editor-wrapper {';

        for (var k in this.state.metadata.props.style) {
            if (this.state.metadata.props.style[k])
                css += this.toLowerDash(k) + ': ' + this.state.metadata.props.style[k] + ';';
        }

        css += '}';

        if (this.state.metadata.props.theme) {
            if (this.state.metadata.props.theme.linkColor)
                css += '.wbp-editor-wrapper a.nav-link { color: ' + this.state.metadata.props.theme.linkColor + ' }';
        }


        return css;
    }

    showComProps(v) {
        this.setComPropsToggle('design');
    }

    setComPropsToggle(v) {
        this.setState({ parentToggle: v });
    }

    generatePageIdent(v) {
        this.setState({ identError: null, checkingIdent: true });
        qreq.post('/api/editor/ident/generate', { id: this.state.metadata.id, name: v, ident: this.state.metadata.ident, siteDomainID: this.props.params.templateID ?? 0 }, j => {
            if (j.errorCode) {
                this.setState({ identError: j.errorMessage, metadata: { ...this.state.metadata, ident: v } });
            }
            else if (j.item) {
                this.setState({ metadata: { ...this.state.metadata, ident: j.item } });
                this.pageIdentChanged(j.item);
            }
        }, this.unkownErrorCallback);
    }

    undo() {
        var hist = [...this.state.history];
        var idx = hist.findIndex(a => a.current === true);
        if(idx >= 0) {
            idx++;
            if(idx < hist.length) {
                hist.forEach(i => { i.current = false });
                hist[idx].current = true;
                this.setState({ containers: JSON.parse(hist[idx].containersJson),
                    headerContainers: JSON.parse(hist[idx].headerContainersJson),
                    footerContainers: JSON.parse(hist[idx].footerContainersJson),
                    history: hist});
            }
        }
    }

    redo() {
        var hist = [...this.state.history];
        var idx = hist.findIndex(a => a.current === true);
        if(idx >= 0) {
            idx--;
            if(idx >= 0) {
                hist.forEach(i => { i.current = false });
                hist[idx].current = true;
                this.setState({ containers: JSON.parse(hist[idx].containersJson),
                    headerContainers: JSON.parse(hist[idx].headerContainersJson),
                    footerContainers: JSON.parse(hist[idx].footerContainersJson),
                    history: hist});
            }
        }
    }

    canUndo() {
        var hist = [...this.state.history];
        var idx = hist.findIndex(a => a.current === true);
        return idx < hist.length - 1;
    }

    canRedo() {
        var hist = [...this.state.history];
        var idx = hist.findIndex(a => a.current === true);
        return idx > 0;
    }

    pageIdentChanged(v) {
        this.setState({ identError: null, checkingIdent: true });
        qreq.get('/api/editor/ident/exists?ident=' + v + '&siteDomainID=' + this.props.params.templateID + '&pageID=' + (this.state.metadata?.id ?? '0'), j => {
            if (j.errorCode)
                this.setState({ identError: j.errorMessage });
            else
                this.setState({ identError: null });
            this.setState({ checkingIdent: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ checkingIdent: false, identError: 'Internal server error. Please modify the ident and try again.' });
        });
    }

    render() {
        if(!this.state.loaded)
            return;
        return (<>
            <div className="wbp-move-floater" ref={this.moveFloaterRef} style={{ display: 'none' }}>Insert Here</div>
            <LoadingModal show={!this.state.pageLoaded} />
            <LauncherModal show={this.state.showLauncherModal} onClose={() => this.setState({ showLauncherModal: false })} />
            <DomainSearch show={this.state.showDomainSearch} onHide={() => this.setState({ showDomainSearch: false })} />
            <HTMLEditorModal />
            <AIModal />
            <Modal show={this.state.showSaveModal} onHide={() => this.setState({ showSaveModal: false })}>
                <form onSubmit={this.save}>
                    <Modal.Header closeButton>
                        <Modal.Title><L>Save Page</L></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-none">
                            <FormInput model={this.state.metadata} name="type" label="Type" type="select" onChange={this.onSelectType} options={[{ id: 'page', name: 'Page' }, { id: 'component', name: 'Component' }]} />
                        </div>
                        <FormInput model={this.state.metadata} name="name" label="Name" type="text" required onChange={this.generatePageIdent} />

                        <FormInput model={this.state.metadata} name="index" label="Make this the home page." type="switch" required onChange={() => { this.setState({ metadata: { ...this.state.metadata, ident: 'index' } }); this.generatePageIdent('index'); }} />

                        {this.state.editIdent ? <FormInput model={this.state.metadata} name="ident" label="Path" type="text" required onChange={this.pageIdentChanged} /> : <><label class="form-label">Path</label><div className="small">/{this.state.metadata.ident} <span role="button" className="text-primary" onClick={() => this.setState({ editIdent: true })}>edit</span></div></>}

                        {this.state.identError ? <Alert type="danger">{this.state.identError}</Alert> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-success" disabled={this.state.saving || this.state.identError || this.state.checkingIdent}>
                            <FontAwesomeIcon icon={fa.faSave} /> <L>Save</L>
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.setState({ showSaveModal: false })} disabled={this.state.saving}>
                            Close
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal show={this.state.showThemePropsModal} onHide={() => this.setState({ showThemePropsModal: false })} size="xl">
                <form onSubmit={this.save}>
                    <Modal.Header closeButton>
                        <Modal.Title><L>Theme Properties</L></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PropertiesEditor col={this.state.metadata.props} pageProps onChange={() => this.setState({ metadata: this.state.metadata })} />
                    </Modal.Body>
                    <Modal.Footer>
                        <small><L>Changes are automatically applied.</L></small>
                        <Button variant="secondary" onClick={() => this.setState({ showThemePropsModal: false })}>
                            <L>Close</L>
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <style>
                {this.getCSS()}
            </style>


            <div className="c-editor">
                <EditorHeader controlFunctions={this.controlFunctions} domain={this.state.domain} metadata={this.state.metadata} payment={this.state.payment} />
                <div className={'wbp-editor ' + (this.state.sidebarExpanded ? 'sidebar-expanded' : '')}>
                    <EditorSidebar
                        onNavSave={this.onNavSave}
                        template={this.props.template}
                        components={this.state.components}
                        componentsLoaded={this.state.componentsLoaded}
                        focusMove={this.focusMoveComponent}
                        setSidebarExpanded={this.setSidebarExpanded}
                        containers={this.state.containers}
                        controlFunctions={this.controlFunctions}
                        toolbarStatus={this.state.toolbarStatus}
                        metadata={this.state.metadata}
                        setMetadata={this.setMetadata}
                        col={this.state.selectedColumn}
                        container={this.state.selectedContainer}
                        parentToggle={this.state.parentToggle}
                        setSelectedColumn={this.setSelectedColumn}
                        setSelectedContainer={this.setSelectedContainer}
                        setComPropsToggle={this.setComPropsToggle}
                        showTutorial={!this.state.showLauncherModal}
                    />
                    <div className="wbp-editor-header">
                        {this.state.headerContainers.length === 0 ? <div className="wbp-placeholder wbp-header-color">HEADER</div> : ''}

                        {this.state.headerContainers.map(a => (
                            <div key={a.id} onClick={() => this.setSelectedContainer(a)}>
                                <EditorContainer navVer={this.state.navVer} metadata={this.state.metadata} container={a} createCol={this.controlFunctions.createCol} removeCol={this.controlFunctions.removeCol} removeColByID={this.removeColByID} focusMove={this.focusMove} setSelectedColumn={this.setSelectedColumn} setMainToolbarStatus={this.setToolbarStatus} showComProps={this.showComProps} snap={this.snap} />
                            </div>
                        ))}

                        <div className="wbp-hint-append">
                            <L>Append to End</L>
                        </div>
                    </div>
                    <div className="wbp-editor-wrapper">
                        {this.state.containers.length === 0 ? <div className="wbp-placeholder wbp-body-color">BODY</div> : ''}

                        {this.state.containers.map(a => (
                            <div key={a.id} onClick={() => this.setSelectedContainer(a)}>
                                <EditorContainer navVer={this.state.navVer} metadata={this.state.metadata} container={a} createCol={this.controlFunctions.createCol} removeCol={this.controlFunctions.removeCol} removeColByID={this.removeColByID} focusMove={this.focusMove} setSelectedColumn={this.setSelectedColumn} setMainToolbarStatus={this.setToolbarStatus} showComProps={this.showComProps} snap={this.snap} />
                            </div>
                        ))}

                        <div className="wbp-hint-append">
                            <L>Append to End</L>
                        </div>
                    </div>
                    <div className="wbp-editor-footer">
                        {this.state.footerContainers.length === 0 ? <div className="wbp-placeholder wbp-footer-color">FOOTER</div> : ''}

                        {this.state.footerContainers.map(a => (
                            <div key={a.id} onClick={() => this.setSelectedContainer(a)}>
                                <EditorContainer navVer={this.state.navVer} metadata={this.state.metadata} container={a} createCol={this.controlFunctions.createCol} removeCol={this.controlFunctions.removeCol} removeColByID={this.removeColByID} focusMove={this.focusMove} setSelectedColumn={this.setSelectedColumn} setMainToolbarStatus={this.setToolbarStatus} showComProps={this.showComProps} snap={this.snap} />
                            </div>
                        ))}

                        <div className="wbp-hint-append">
                            <L>Append to End</L>
                        </div>
                    </div>
                </div>
            </div>

        </>
        );
    }
}

export default withParamsAndNavigate(Editor);