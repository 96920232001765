import './LauncherModal.css';
import BaseCom from "./BaseCom";
import SelectTemplate from "./SelectTemplate";
import Icon from './Icon';
import SelectTemplateMode from './SelectTemplateMode';
import AISurvey from './AISurvey';
import { qreq } from '../shared/qrequest';
import { withParamsAndNavigate } from './WithParamsAndNavigate';
import DomainSearch from './DomainSearch';
import L from './Lang';
import { Pop } from './PopModal';

class LauncherModal extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            page: 'initial'
        };
        this.selectTemplate = this.selectTemplate.bind(this);
    }

    componentDidMount() {
        qreq.get('/api/organization/get/current?siteDomainID=' + (this.props.params.templateID ?? '0'), j => {
            if (j.item) {
                if (j.item.workflow === 'pay-to-publish')
                    this.setState({ page: 'domain-select' });
                this.setState({ organization: j.item });
            }
        });
    }

    selectTemplate() {
        Pop.showConfirm(<L>Are you sure you want to use the template without AI?</L>, 'Use Template', () => {
            this.setState({ selecting: true });
            qreq.post('/api/editor/template/select?siteDomainID=' + (this.props.params.templateID ?? '0'), { ...this.state.tpl }, j => {
                if (j.errorCode === 0) {
                    window.location.reload();
                }
                else {
                    this.alert(j.errorMessage);
                    this.setState({ selecting: false });
                }
            }, () => {
                this.unkownErrorCallback();
                this.setState({ selecting: false });
            });
        });
    }

    scheduleCall = () => {
        qreq.get('/api/organization/get/current?siteDomainID=' + (this.props.params.templateID ?? '0'), j => {
            if (j.item)
                window.location.assign(j.item.supportURL);
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    render() {
        if (!this.props.show)
            return <></>;
        if (this.state.page === 'domain-select')
            return <DomainSearch show reserveOnly onSuccess={() => this.setState({ page: 'initial' })} />;
        return <>
            <div className="fade modal-backdrop show"></div>
            <div className="modal fade show LauncherModal" role="dialog" style={{ display: 'block' }}>
                <div className={'modal-dialog modal-dialog-centered' + (this.state.page === 'template' ? ' modal-lg' : ' modal-xl')}>
                    <div className="modal-content modal-content-style" style={{ backgroundColor: this.state.page === 'domain-select' ? '#fedab3' : '#fedab300', border: 'none' }}>
                        {this.state.page === 'template-select' ? <SelectTemplate onSelect={tpl => this.setState({ tpl: tpl, page: 'template-ai' })} onBack={() => this.setState({ page: 'initial' })} onClose={this.props.onClose} />
                            : this.state.page === 'template' ? <SelectTemplateMode onBack={() => this.setState({ page: 'template-ai' })} setPage={v => this.setState({ page: v })} tpl={this.state.tpl} onSelect={this.selectTemplate} />
                                : this.state.page === 'template-ai' ? <AISurvey onBack={() => this.setState({ page: 'template-select' })} onTemplateOnly={this.selectTemplate} setPage={v => this.setState({ page: v })} tpl={this.state.tpl} onComplete={this.props.onClose} />
                                    : <div className="container-fluid mt-2">
                                        {this.props.onClose ?
                                            <span className="float-end fs-2 text-white">
                                                <span role="button" title="Close" aria-label="Close" onClick={this.props.onClose}>
                                                    <Icon icon="faTimes" />
                                                </span>
                                            </span> : ''}
                                        <h1 className="text-center mb-3 modal-header-weight text-shadow">
                                            <L>Welcome to Your Web Builder.</L>
                                        </h1>
                                        <div className="d-block d-md-flex gap-3 align-items-stretch">

                                            <div className="card custom-card3 mb-4">
                                                <div className="card-body">
                                                    <h4 className="welcome-title">
                                                        <L>Begin Your Website Using AI</L>
                                                    </h4>
                                                    <div className="row">
                                                        <div className="col-lg-6 welcome-card-text">
                                                            <L>
                                                                Use our AI features to generate your site, offering you expertly-designed website templates that are fully customizable. This allows you to create a bold & effective web presence for your business effortlessly.
                                                            </L>
                                                        </div>
                                                        <div className="col-lg-6 text-center">
                                                            <div>
                                                                <img src="/img/ai.png" className="img-fluid welcome-img-2" alt="with the pros" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-buttons">
                                                        <button type="button" className="btn btn-success text-body" onClick={() => this.setState({ page: 'template-select' })}><L>Generate with AI</L></button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card custom-card3 mb-4">
                                                <div className="card-body">
                                                    <h4 className="welcome-title">
                                                        <L>Build Your Own Site</L>
                                                    </h4>
                                                    <div className="row">
                                                        <div className="col-lg-6 welcome-card-text">
                                                            <L>
                                                                Using our easy to understand tools you can build your own
                                                                website from scratch! Bring your vision to life, have it
                                                                deliver your message and branding, and when complete
                                                                publish it to the world!
                                                            </L>
                                                        </div>
                                                        <div className="col-lg-6 text-center">
                                                            <div>
                                                                <img src="/img/launcher01.png" className="img-fluid welcome-img-1" alt="with the pros" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-buttons">
                                                        <button className="btn btn-success text-body" onClick={this.props.onClose} data-bs-dismiss="modal"><L>Begin from Scratch</L></button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="d-block d-md-flex gap-3 align-items-stretch">
                                            <div className="card custom-card3 mb-4">
                                                <div className="card-body">
                                                    <h4 className="welcome-title">
                                                        <L>Professional Web Design</L>
                                                    </h4>
                                                    <div className="row">
                                                        <div className="col-lg-6 welcome-card-text">
                                                            <L>
                                                                Let our team of design experts
                                                                build a custom website for your
                                                                business. We'll provide a draft
                                                                for your approval within 5
                                                                business days and will work
                                                                with you to tweak your site to
                                                                fit your needs.
                                                            </L>
                                                        </div>
                                                        <div className="col-lg-6 text-center">
                                                            <div>
                                                                <img src="/img/launcher03.png" className="img-fluid welcome-img-3" alt="with the pros" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-buttons">
                                                        <button type="button" className="btn btn-success text-body" onClick={this.scheduleCall}><L>Let's Get Started</L></button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card custom-card3 mb-4">
                                                <div className="card-body">
                                                    <h4 className="welcome-title">
                                                        <L>Schedule A Call</L>
                                                    </h4>
                                                    <div className="row">
                                                        <div className="col-lg-6 welcome-card-text">
                                                            <L>
                                                                Don't know if you want to DIY or let
                                                                our team take over the design process?
                                                                Talk with a small business expert to
                                                                find the best solution for your
                                                                business. We're here to help you
                                                                make your vision a reality.
                                                            </L>
                                                        </div>
                                                        <div className="col-lg-6 text-center">
                                                            <div>
                                                                <img src="/img/launcher04.png" className="img-fluid welcome-img-4" alt="with the pros" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-buttons">
                                                        <button type="button" className="btn btn-success text-body" onClick={this.scheduleCall}><L>Schedule</L></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                    </div>
                </div>
            </div>
        </>;
    }
}

export default withParamsAndNavigate(LauncherModal);