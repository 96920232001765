import { Link } from "react-router-dom";
import Alert from "./Alert";
import BaseCom from "./BaseCom";
import ComPreview from "./ComPreview";
import Icon from "./Icon";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class Components extends BaseCom {
    name = (v) => {
        if(!v) return '';
        if(v.startsWith('Heading '))
            v = v.replace('Heading ', 'H');
        return v;
    }

    render() {
        return this.props.components.length === 0 ? <Alert message="There are no items in this list." /> :
        this.props.components.map(obj => {
            if (!obj.subCom && !obj.isPlaceholder && ((this.props.toggle === 'com' && !obj.sitePageID) || (this.props.toggle === 'sec' && obj.sitePageID))) {
                return <div key={obj.id} className="com-com col-6">
                    <div id={'c-com-' + obj.id} onMouseDown={e => this.props.focusMove(e, obj)} className="move">
                        {this.props.toggle === 'com' ? <div className="my-2"><ComPreview com={obj} /></div> : <div className="card text-center mb-4 pt-4 pb-4">
                            <div className="icon"><Icon icon={obj.icon} size="xl" /></div>
                            <div className="mt-1">{this.name(obj.name)}</div>
                        </div>}
                    </div>
                    {this.props.toggle === 'sec' ? <div className="com-edit-link"><Link to={(this.props.params.templateID ? '/editor/template/' + this.props.params.templateID + '/page/' : '/editor/page/') + obj.sitePageID}>edit</Link></div> : ''}
                </div>;
            }
            return null;
        });
    }
}

export default withParamsAndNavigate(Components);